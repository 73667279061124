<template>
  <div>
    <portal to="subheader_portal">
      <router-link :to="{ name: 'fuel_dispenser_create' }">
        <b-button variant="success"
          ><b-icon icon="plus" aria-hidden="true"></b-icon> Nuevo
          surtidor</b-button
        >
      </router-link>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div class="table-responsive">
              <table class="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th class="p-0" style="width: 50px"></th>
                    <th class="p-0" style="min-width: 200px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                    <th class="p-0" style="min-width: 125px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="pl-0 py-4">
                      <div class="symbol symbol-50 symbol-light mr-1">
                        <span class="symbol-label"
                          ><img
                            src="media/misc/invoice.png"
                            alt=""
                            class="h-50 align-self-center"
                        /></span>
                      </div>
                    </td>
                    <td class="pl-0">
                      <a
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        Nombre:
                      </a>
                      <div>
                        <span class="font-weight-bolder">{{ item.name }}</span>
                      </div>
                    </td>
                    <td class="">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >Ubicación: {{ item.warehouse__name }}
                      </span>
                    </td>
                    <td class="">
                      <b-button
                        size="sm"
                        class="mb-2 mr-2"
                        variant="primary"
                        @click="editItem(item)"
                        title="Editar"
                      >
                        <b-icon
                          icon="pencil-square"
                          aria-hidden="true"
                        ></b-icon>
                      </b-button>
                      <b-button
                        size="sm"
                        class="mb-2 mr-2"
                        variant="success"
                        @click="enterPOS(item)"
                        title="POS"
                      >
                        <b-icon icon="calculator" aria-hidden="true"></b-icon>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "LogisticsDispatchList",
  data() {
    return {
      invoice: null,
      form: {
        start_date: null,
        end_date: null,
      },
      page: 1,
      rows: 0,
      perPage: 10,
      fields: [
        { key: "figure", label: "" },
        { key: "serie", label: "Serie" },
        { key: "number", label: "Número" },
        { key: "customer", label: "Cliente" },
        { key: "delivery_status", label: "Delivery" },
        { key: "total", label: "Total" },
        { key: "actions", label: "acciones" },
      ],
      items: [],
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({
        name: `fuel_dispenser_edit`,
        params: { id: item.id },
      });
    },
    enterPOS(item) {
      this.$router.push({
        name: `fuel_dispenser_pos`,
        params: { id: item.id },
      });
    },
    generateExcel() {
      const api_url = localStorage.getItem("API_URL");
      const url = `https://${api_url}/logistics/dispatch/export/excel/?start_date=${this.form.start_date}&end_date=${this.form.end_date}`;
      window.open(url, "_blank");
    },
    search() {
      this.getItems(1, this.form.start_date, this.form.end_date);
    },
    getItems(page = 1) {
      let slug = `?page=${page}&page_size=${this.perPage}`;
      ApiService.get("fuel/dispenser", slug).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getItems(value);
    },
  },
  mounted() {
    this.getItems();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Surtidores" }]);
    const now = new Date().toISOString().slice(0, 10);
    this.form.start_date = now;
    this.form.end_date = now;
  },
};
</script>
